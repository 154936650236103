
import React from "react";
import MathUtils from "../service/math-utils";
import Style from "../service/style";
import Activity from "../model/activity";

export default class Legend extends React.Component<any, any> {

    override render(): React.ReactNode {

        let total = 0;
        let totalKmTallinn = 0;
        let total2025 = 0;
        let total2024 = 0;
        let total2023 = 0;
        let total2022 = 0;
        let total2021 = 0;

        this.props.runs.forEach((activity: Activity) => {
            total += activity.distance;
            if (activity.isInTallinn()) {
                totalKmTallinn += activity.distance;
            }
            if (activity.isIn2025()) {
                total2025 += activity.distance;
            }
            if (activity.isIn2024()) {
                total2024 += activity.distance;
            }
            if (activity.isIn2023()) {
                total2023 += activity.distance;
            }
            if (activity.isIn2022()) {
                total2022 += activity.distance;
            }
            if (activity.isIn2021()) {
                total2021 += activity.distance;
            }
        });

        total = MathUtils.toKm(total);
        totalKmTallinn = MathUtils.toKm(totalKmTallinn);
        total2025 = MathUtils.toKm(total2025);
        total2024 = MathUtils.toKm(total2024);
        total2023 = MathUtils.toKm(total2023);
        total2022 = MathUtils.toKm(total2022);
        total2021 = MathUtils.toKm(total2021);

        const entries = [
            { key: "Grand total", value: total + "km" },
            { key: "Total in Tallinn", value: totalKmTallinn + "km" },
            { key: "Total in 2025", value: total2025 + "km" },
            { key: "Total in 2024", value: total2024 + "km" },
            { key: "Total in 2023", value: total2023 + "km" },
            { key: "Total in 2022", value: total2022 + "km" },
            { key: "Total in 2021", value: total2021 + "km" },
        ]

        return (
            <div style={{
                width: 220,
                backgroundColor: "white",
                boxShadow: Style.SHADOW,
                borderRadius: 3,
                font: Style.FONT,
                padding: 10
            }}>
                {entries.map((entry: any) => {
                    return (<div key={entry.key} style={{ display: "flex", paddingBottom: 3 }}>
                        <span style={{ flex: 5, fontWeight: 600 }}>{entry.key.toUpperCase()}</span>
                        <span style={{ flex: 3 }}>{entry.value}</span>
                    </div>);
                })}
            </div>
        )
    }
}
